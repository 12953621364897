import React from 'react'
import { Image } from 'react-bootstrap'
import { LazyLoadComponent } from 'react-lazy-load-image-component';

function Thumbnail({href,thumbnail, alt}){
    return(
        thumbnail ?
        href ? 
            <a href={href} target="_blank" rel="noopener noreferrer">
                <LazyLoadComponent>
                    <Image src={thumbnail} style={{zoom:"125%", maxWidth:"100%"}} alt={alt} rounded fluid/>
                </LazyLoadComponent> 
            </a>
            :<LazyLoadComponent>
                <Image src={thumbnail} style={{zoom:"125%", maxWidth:"100%"}} alt={alt} rounded fluid/>
            </LazyLoadComponent>
        :null 
    )
}

export default Thumbnail