import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import './style.css'

function BuyButtonBar({book}){
    return(
        <ButtonToolbar className="btnbar">
            <div width="50%">
            { book.geniusLink ? <Button href={book.geniusLink} target="_blank" rel="noreferrer" aria-label={book.title} className="amznbtn"></Button>:
              book.affiliateLink ? <Button href={book.affiliateLink} target="_blank" rel="noreferrer" aria-label={book.title} className="amznbtn"></Button>:
              book.amazonLink ? <Button href={book.amazonLink} target="_blank" rel="noreferrer" aria-label={book.title} className="amznbtn"></Button> : null 
            }
            </div>
            <div width="50%">
            { book.onlineLink ? <Button variant="success" href={book.onlineLink} target="_blank" rel="noreferrer" className="buybtn">View</Button> : null}
            { book.onlineBuyLink ? <Button variant="success" href={book.onlineBuyLink} target="_blank" rel="noreferrer" className="buybtn">Buy on Site</Button> : null}
            { book.indieboundLink ? <Button href={book.indieboundLink} target="_blank" rel="noreferrer" className="indiebtn" aria-label={book.title}></Button> : null}
            </div>
        </ButtonToolbar> 
    )
}

export default BuyButtonBar;