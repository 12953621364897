import React, { useState } from 'react'
import LinesEllipsis from 'react-lines-ellipsis'

import './style.css'

function BookDescription({book}){
    const description = book.description? book.description: null
    const [isExpand, setExpand] = useState(false);

    if(description){
        return(
            <div className="book-description">
                {isExpand ? (<div onClick={() => setExpand(false)}>{description}<span className="LinesEllipsis-ellipsis"> Show less</span></div>)
                : (
                    <div onClick={() => setExpand(true)}>
                        <LinesEllipsis style={{ whiteSpace: 'pre-wrap', wordBreak: "break-word" }}
                        text={description} 
                        maxLine="5" 
                        ellipsis="... more" 
                        basedOn="words" 
                        />
                    </div>
                )} 
            </div> 
        )
    }

    return null
    
}

export default BookDescription;